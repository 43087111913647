@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  text-align: center;
  background: #f1eae4;

  & button {
    margin: 32px auto 42px;
    width: 204px;
    height: 56px;
    background: #bf5c3a;
    font: normal normal bold 20px/36px Roc Grotesk;
    letter-spacing: 0px;
    color: #ffffff;
    display: flex;
        align-items: center;
        justify-content: center;
  }

  & p {
    margin-bottom: 0px;
    padding: 0;
    margin-top: 0;
  }
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
  &>span {
    color: #bf5c3a;
    font-size: 14px;
  }
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}

form {
  & p {
    font: normal normal normal 20px/32px Noto Sans;
    letter-spacing: 0px;
    color: #3a3d3a;
    max-width: 770px;
    margin: 0 auto 42px;

    @media (max-width: 1023px) {
      margin: 0 auto 32px;
    }

    @media (max-width: 767px) {
      font: normal normal normal 16px/20px Noto Sans;
    }
  }

  & .loginContents {
    text-align: center;

    & .logoProject {
      display: block;
      margin: 0 auto;

      @media (max-width: 767px) {
        width: 160px;
      }
    }

    & .loveLogo {
      width: 150px;
      height: 80px;
      margin: 163px 0 16px;

      @media (max-width: 1550px) {
        margin: 80px 0 16px;
      }

      @media (max-width: 767px) {
        width: 130px;
        height: 70px;
      }
    }

    & h1 {
      font: normal normal 900 80px/90px Roc Grotesk;
      letter-spacing: 0px;
      color: #3a3d3a;
      max-width: 650px;
      margin: 0 auto;

      @media (max-width: 1550px) {
        font: normal normal 900 70px/80px Roc Grotesk;
      }

      @media (max-width: 1360px) {
        font: normal normal 900 50px/60px Roc Grotesk;
      }

      @media (max-width: 767px) {
        font: normal normal 900 36px/44px Roc Grotesk;
      }
    }

    & h5 {
      font: normal normal 500 28px/44px Roc Grotesk;
      letter-spacing: 0px;
      color: #3a3d3a;
      margin: 24px 0 36px;

      @media (max-width: 767px) {
        font: normal normal 500 22px/28px Roc Grotesk;
      }
    }
  }

  & .inputWrapper {
    max-width: 718px;
    margin: 0 auto;
    width: 100%;

    & label {
      display: none;
    }

    & input {
      background-color: transparent;
      border-radius: unset;
      border: none;
      border-bottom: 2px solid hwb(120 23% 76% / 0.507);
      font: normal normal normal 16px/32px Noto Sans;
      letter-spacing: 0px;
      color: #3a3d3a;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }

      @media (min-width: 1024px) {
        padding-left: 36px;
      }
    }
  }

  & .joinNow {
    font: normal normal 500 20px/32px Roc Grotesk;
    letter-spacing: 0px;
    color: #bf5c3a;
    text-decoration: underline;
    white-space: pre;
    cursor: pointer;

    @media (max-width: 767px) {
      font: normal normal 500 16px/20px Roc Grotesk;
      display: block;
      margin-top: 6px;
    }
  }
}

.customerSignUpBottom {
  text-align: left;
  background: #fff;
  margin-top: 44px;

  &>p {
    margin-bottom: 34px;

    & span {
      letter-spacing: 0px;
      color: #3a3d3a;
      font-size: 16px;
      font-weight: 700;
    }
  }

  &>span {
    & span {
      color: #3a3d3a;
      opacity: 1;
      font-weight: 400;
    }
  }

  &>div {
    margin-top: 10px;

    & p {
      letter-spacing: 0px;
      color: #3a3d3a;
      font-weight: 400;
      font-size: 16px;
    }
  }

  & button {
    width: 100%;
    height: 70px;

    @media (max-width:767px) {
      height: 50px;
    }
  }
}